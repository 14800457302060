<nav class="navbar navbar-primary static-top">
    <div class="container-fluid">
        <span class="text-white" id="userName">{{ name }}</span>
        <nav class="d-flex flex-row flex-nowrap">
            <ul class="nav nav-pills nav-lang">
                <li class="nav-item dropdown d-flex align-items-center">
                    <i class="icon-global d-inline-block"></i>
                    <a class="nav-link dropdown-toggle text-white" data-bs-toggle="dropdown" role="button"
                        aria-expanded="false">{{ currentlang }}</a>
                    <ul class="dropdown-menu">
                        <li *ngFor="let item of lang_array">
                            <a class="dropdown-item" (click)="setLang(item)" (touchend)="setLang(item)">
                                {{item.name}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <button type="button" class="btn p-0 pe-1 me-4" id="menuLoginButton" data-bs-toggle="modal"
                data-bs-target="#example">
                <img src="../../assets/images/logout.svg" />
            </button>
        </nav>
    </div>
</nav>

<section id="pageOverall" class="features-icons bg-light text-center d-flex">
    <div *ngIf="control">
        <div class="loading-event-pause w-100 d-flex" id="loading-event">
            <div class="loading-wrapper d-flex flex-column justify-content-center">
                <h2 class="font-mulish">{{ "HOME.BREAK" | translate }}</h2>
                <p class="mt-5 text-center">
                    <i class="icon-loading-time d-inline-block"></i>
                </p>
                <p class="d-flex justify-content-center">
                    <button class="btn btn-loading d-flex justify-content-start align-items-center"
                        (click)="controlStatus()" (touchend)="controlStatus()">
                        <i class="icon-control-pause d-inline-block"></i>
                        <span>{{ "HOME.RESUME" | translate }}</span>
                    </button>
                </p>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div id="userBar" class="row bg-gray position-relative">
            <div class="user-control p-0 d-flex justify-content-center icon-button-wrapper cstm-icon-button-wrapper">
                <button (click)="add_order('s','')" (touchend)="add_order('s','')"
                    class="btn btn-light-blue w-100 p-0 h-100 d-flex justify-content-center align-items-center">
                    <i class="icon-white-doc m-0"></i>
                    <i class="icon-plus-small ms-1 me-0"></i>
                </button>
                <!-- <button
                    class="btn btn-light-blue w-50 p-0 h-100 border-left d-flex justify-content-center align-items-center"
                    (click)="controlStatus()" (touchend)="controlStatus()" [ngClass]="control ? 'pause' : 'play'">
                    <i class="icon-control d-inline-block m-0"></i>
                </button> -->
            </div>
            <div style="color: white;" *ngFor="let item of active_users"
                class="addUser d-flex justify-content-center align-items-center"
                [ngClass]="{ active: user_screen == true }"> {{ item?.displayName }}
            </div>
        </div>
        <div class="row mainScreen bg-white" *ngIf="status_screen">

            <div class="col-md-2 p-0 orderTabs custom-tab-responsive line"
                [ngClass]="{ blur_button: order_viewdisable == true }">
                <div class="scrollable">
                    <div class="row" [ngClass]="{ active: item?.focus == true }" *ngFor="let item of orders"
                        (click)="focus_button(item)" (touchend)="focus_button(item)">
                        <button class="status-item" [ngClass]="{
                green: item?.eventType == 'green',
                yellow: item?.eventType == 'yellow',
                red: item?.eventType == 'red',
                grey: item?.eventType == 'grey' || item?.eventType == '' || item == ''
              }"></button>
                        <div class="d-flex justify-content-center mt-auto"
                            [ngClass]="{OrderID : item?.eventDescription}">
                            <p class="number m-0">{{ item?.orderID }} <sup class="label remainder-bar" *ngIf="item?.notification &&  item?.eventType == 'yellow'
                                " (click)="open(yellowpopup);get_ordernotification(item)"><b>1</b></sup></p>

                        </div>
                        <span class="d-inline-block eventOrder text-truncate">{{ (item.languageKey) ?
                            ("HOME."+item?.languageKey | translate): item?.eventDescription}}</span>
                    </div>
                    <!-- <div class="col-md-2" (click)="navigate_cal()" (touchend)="navigate_cal()">
                        <button class="status-item werkuren"
                            [ngClass]="{ actives: workinghours_flag == true,blur: control == true }"> {{
                            "HOME.WORKING_HOURS" | translate }}</button>
                    </div> -->
                </div>
                <div class="user-controller-scrole">
                    <div class="user-control p-0 d-flex  icon-button-wrapperr">

                        <button (click)="controlStatus()" (touchend)="controlStatus()"
                            class="bottombtn btn btn-light-blue w-100 p-0 border-left d-flex align-items-center "
                            [ngClass]="control ? 'pause' : 'play'">
                            <i class=" desktopview icon-control d-inline-block m-0"></i> <span
                                class="fontnumber m-0 text-white">
                                {{ "HOME.BREAK" | translate}}
                            </span>
                        </button>
                    </div>
                    <div *ngIf="role != 'office'" class="user-control  p-0 d-flex  icon-button-wrapperr">

                        <button (click)="setnavigation()" (touchend)="setnavigation()"
                            class=" bottombtn btn btn-light-blue w-100 p-0 border-left d-flex align-items-center">
                            <i class=" desktopview fa fa-lightbulb-o"></i> <span class="fontnumber m-0 text-white">
                                {{
                                "HOME.IDEABUTTON" | translate
                                }}
                            </span>
                        </button>

                    </div>
                    <div (click)="navigate_cal()" (touchend)="navigate_cal()"
                        class="user-control  p-0 d-flex  icon-button-wrapperr" style="cursor:pointer;">
                        <!-- [ngClass]="{ actives: workinghours_flag == true,blur: control == true }" -->
                        <button class=" bottombtn btn btn-light-blue w-100 p-0 border-left d-flex align-items-center">
                            <img src="../../assets/alarmwerk.png" class="desktopviewimg" height="43" />
                            <span class=" fontnumber m-0 text-white">
                                {{
                                "HOME.WORKING_HOURS" | translate }}
                            </span></button>

                    </div>
                    <div *ngIf="customizing?.allow_ImprovementIdea"
                        class="user-control  p-0 d-flex justify-content-center icon-button-wrapperr">

                        <button class="bottombtn btn btn-light-blue w-100 p-0 border-left d-flex  align-items-center"
                            (click)="setnavigation()">
                            <i class=" desktopview fa fa-lightbulb-o"></i> <span class=" fontnumber m-0 text-white">
                                {{
                                "HOME.IDEABUTTON" | translate
                                }}
                            </span>
                        </button>

                    </div>
                </div>


            </div>
            <div class="col-md-10 wrapper-list-item d-flex flex-column">
                <div class="row row-center-section">
                    <div class="screenMainSection" *ngIf="workinghours_flag">
                        <app-working-hours [language]="currentlang"></app-working-hours>
                    </div>
                    <div class="screenMainSection" *ngIf="downtime_flag">
                        <app-down-time></app-down-time>
                    </div>
                    <div class="screenMainSection" *ngIf="unplanned_flag">
                        <app-unplanned-down-time></app-unplanned-down-time>
                    </div>
                    <div class="screenMainSection" *ngIf="reports_flag">
                        <app-reports [reportOnly]="blueOrder"></app-reports>
                    </div>
                    <div class="screenMainSection" *ngIf="workingon_flag">
                        <app-working-on></app-working-on>
                    </div>
                    <div class="screenMainSection" *ngIf="project_flag">
                        <app-project-list></app-project-list>
                    </div>
                    <div class="screenMainSection" *ngIf="screen_status"
                        [ngClass]="{ blur_button: order_viewdisable == true }">
                        <!-- <div class="row backbutton">
                            <div class="col-md-3 text-start"><a (click)="navigate()"><i
                                        class="fa fa-arrow-left"></i>&nbsp;&nbsp;{{'HOME.BACKBUTTON' | translate}}</a>
                            </div>

                        </div> -->

                        <div class="row ps-0 pe-0 actionButton action d-flex flex-row flex-nowrap" role=button
                            tabindex="-1" (click)="!isShow?set_flag('green', content):'';$event.preventDefault();">
                            <button class="circle green mx-2 mx-md-4 mx-xl-2 mx-xxl-4"></button>
                            <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                                <div class="row g-0">
                                    <span class="button-text-large pl-2 ml-1 lh-1">{{
                                        "HOME.GREENBUTTON" | translate
                                        }}</span>
                                </div>
                                <div class="row g-0">
                                    <div>
                                        <span class="button-text-small pl-2 ml-1">{{
                                            "HOME.GREEN_ACT" | translate
                                            }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ps-0 pe-0 actionButton d-flex flex-row flex-nowrap" role=button tabindex="-1"
                            (click)="set_flag('yellow', 'content');$event.preventDefault();">
                            <button class="circle yellow mx-2 mx-md-4 mx-xl-2 mx-xxl-4"></button>
                            <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                                <div class="row g-0">
                                    <span class="button-text-large pl-2 ml-1 lh-1">{{
                                        "HOME.YELLOWBUTTON" | translate
                                        }}</span>
                                </div>
                                <div class="row g-0">
                                    <div>
                                        <span class="button-text-small pl-2 ml-1" *ngIf="role == 'office'">{{
                                            "HOME.YELLOW_ACT" | translate
                                            }} {{
                                            "HOME.OFFICETYPE" | translate
                                            }}</span>
                                        <span class="button-text-small pl-2 ml-1" *ngIf="role == 'shopfloor'">{{
                                            "HOME.YELLOW_ACT" | translate
                                            }} {{
                                            "HOME.SHOPFLOORTYPE" | translate
                                            }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ps-0 pe-0 actionButton d-flex flex-row flex-nowrap" role=button tabindex="-1"
                            (click)="set_flag('red', 'content');$event.preventDefault();" data-a="RedStep1"
                            data-level="1" data-eventtype="red">
                            <button class="circle red mx-2 mx-md-4 mx-xl-2 mx-xxl-4"></button>
                            <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                                <div class="row g-0">
                                    <div>
                                        <span class="button-text-large pl-2 ml-1 lh-1">{{
                                            "HOME.REDBUTTON" | translate
                                            }} </span>

                                    </div>
                                </div>
                                <div class="row g-0">
                                    <div>
                                        <span class="button-text-small pl-2 ml-1" *ngIf="role == 'office'">{{
                                            "HOME.RED.RED_ACT" | translate
                                            }} {{ "HOME.OFFICETYPE" | translate}}</span>
                                        <span class="button-text-small pl-2 ml-1" *ngIf="role == 'shopfloor'">{{
                                            "HOME.RED.RED_ACT" | translate
                                            }} {{ "HOME.SHOPFLOORTYPE" | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf=" +this.customizing?.allow_andon !== 0"
                            class="row ps-0 pe-0 actionButton d-flex flex-row flex-nowrap" role=button tabindex="-1"
                            (click)="set_flag('blue', 'content');$event.preventDefault();" data-a="BlueStep1"
                            data-level="1" data-eventtype="blue">
                            <button class="circle blue mx-2 mx-md-4 mx-xl-2 mx-xxl-4"></button>
                            <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                                <div class="row g-0">
                                    <span class="button-text-large pl-2 ml-1 lh-1">
                                        {{ "HOME.BLUEBUTTON" | translate
                                        }}</span>
                                </div>
                                <div class="row g-0">
                                    <div>
                                        <span class="button-text-small pl-2 ml-1" *ngIf="role == 'office'">{{
                                            "HOME.BLUE_ACT" | translate
                                            }} {{"HOME.OFFICETYPE" | translate}}</span>
                                        <span class="button-text-small pl-2 ml-1" *ngIf="role == 'shopfloor'">{{
                                            "HOME.BLUE_ACT" | translate
                                            }} {{"HOME.SHOPFLOORTYPE" | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ps-0 actionButton d-flex flex-row flex-nowrap" role=button
                            *ngIf="attachment_flag" tabindex="-1" [ngClass]="{ blur_button: !attachments }"
                            (click)="open_attachment();$event.preventDefault();">
                            <div
                                class="icon-doc-wrapper text-center mx-2 mx-md-4 mx-xl-2 mx-xxl-4 p-0 align-self-center d-flex align-items-center justify-content-center">
                                <button class="icon-docs border-0 bg-white"></button>
                            </div>
                            <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                                <div class="row g-0">
                                    <span class="button-text-large pl-2 lh-1">{{
                                        "HOME.INSTRUCTDOCUMENT" | translate
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="statusScreen">
                        <div class="status-box" *ngIf="reportstatus_flag"
                            [ngClass]="{ blue: reportstatus_flag == true }">
                            <div class="text-align my-auto blueStatusBar">
                                <p class="font-mulish font-sm"
                                    *ngIf="report_event.languageKey != null && report_event.languageKey != ''">
                                    {{ "HOME."+report_event?.languageKey | translate }}
                                </p>
                                <p class="font-mulish font-sm"
                                    *ngIf="report_event.languageKey == null || report_event.languageKey == ''">
                                    {{ report_event?.eventDescription }}
                                </p>
                                <p>
                                    <button type="button" class="btn btn-secondary"
                                        (click)="stop(report_event, 'blue',true)">
                                        {{ "HOME.READYBUTTON" | translate }}
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div class="status-box" [ngClass]="{
                green: active_order?.eventType == 'green',
                yellow: active_order?.eventType == 'yellow',
                red: active_order?.eventType == 'red',
                grey:
                  active_order == undefined ||
                  active_order?.eventType == 'grey' ||
                  active_order?.eventType == '',
                darkblue: active_order?.eventType == 'darkblue'
              }">
                            <div class="text-align timer running" *ngIf="timer_flag">
                                {{ t }}
                            </div>
                            <span class="activeColorText font-mulish font-sm"
                                *ngIf="active_order?.eventType == 'yellow'">{{ "HOME.YELLOWBUTTON" | translate }}</span>
                            <span class="activeColorText font-mulish font-sm"
                                *ngIf="active_order?.eventType == 'red'">{{ "HOME.REDBUTTON" | translate }}</span>
                            <span class="activeColorTextDescription font-mulish font-sm" *ngIf="
                active_order != undefined &&
                (active_order?.eventType != 'grey' &&
                active_order?.eventType != 'green' &&
                active_order?.eventType != '') &&
                (active_order?.languageKey) != '' && (active_order?.languageKey) != null
              ">{{ "HOME."+active_order?.languageKey | translate }}</span>
                            <span class="activeColorTextDescription font-mulish font-sm" *ngIf="
              active_order != undefined &&
              (active_order?.eventType != 'grey' &&
                active_order?.eventType != 'green' &&
                active_order?.eventType != '') &&
              (active_order?.languageKey) == '' || (active_order?.languageKey) == null
            ">{{ active_order?.eventDescription }}</span>
                            <span class="activeColorTextDescription font-mulish font-sm" *ngIf="
                  active_order != undefined &&
                  active_order?.eventType == 'green'
                ">{{ "HOME.GREENBUTTON" | translate }}</span>
                        </div>
                        <div class="statusScreen--last position-relative" (click)="delete_order()"
                            *ngIf="workinghours_flag ==  false || darkblue_event.length > 0">
                            <div class="statusScreen--last-wrapper closeOrderBtn">
                                <div class="d-flex flex-row flex-nowrap align-items-center">
                                    <i class="icon-close"></i>
                                    <div>{{ "HOME.CLOSEBUTTON" | translate }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="statusScreen--last position-relative "
                            *ngIf="workinghours_flag ==  true  && darkblue_event.length == 0"
                            (click)="setModal('true')">
                            <div class="statusScreen--last-wrapper closeOrderBtn startnow">
                                <div class="d-flex flex-row flex-nowrap align-items-center textwrap">
                                    <!-- <i class="fa fa-play-circle"></i> -->
                                    <i class="fa fa-play-circle circleStart"></i>
                                    {{ "HOME.STARTNOW_BUTTON" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mainScreen mt-5" style="display: flex" *ngIf="user_screen">
            <div class="col-md-1"></div>
            <div class="col-md-10 my-auto mt-5">
                <div class="modal-content userOverview">
                    <div class="modal-header">
                        <h5 class="modal-title" id="loginModalLabel">
                            {{ "HOME.CHOOSEUSER" | translate }}
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <a class="btn btn-primary btn-lg btn-block active" *ngFor="let items of available_users"
                                role="button" aria-pressed="true">{{ items.firstName }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</section>

<br>

<div class="modal fade" id="example" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="example">
                    {{ "HOME.LOGOUT" | translate }}
                </h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h2>{{ "HOME.LOGOUTCONTENT" | translate }}</h2>
            </div>
            <div class="modal-footer d-flex justify-content-end">
                <button type="button" class="btn btn-primary logout" data-bs-dismiss="modal" (click)="logout()">
                    {{ "HOME.CONFIRMBUTTON" | translate }}
                </button>
                <button type="button" class="btn btn-success reset logout" data-bs-dismiss="modal">
                    {{ "HOME.CANCELBUTTON" | translate }}
                </button>

            </div>
        </div>
    </div>
</div>
<div class="attention"></div>

<ng-template #yellowpopup let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">
            {{ "HOME.REMINDER_TITLE" | translate }}
        </h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="statusForm" novalidate class="d-flex flex-column m-auto">
            <div class="form-group row " style="display: flex;">
                <label class="col-sm-11 col-form-label">
                    <p>
                        {{ "HOME.REMINDER_DESCP" | translate }}
                    </p>
                </label>
            </div>
            <div class="row">
                <div class="col-sm-1 align-self-center">
                    <input class="form-check-input" type="radio" formControlName="red_radio" value="false"
                        id="gridRadios1">
                </div>
                <label for="inputEmail3" class="col-sm-4 col-form-label">{{ "HOME.SET_NEW_DATE" | translate }}</label>
                <div class="col-sm-7">
                    <!-- [min]="minDate" -->

                    <mat-form-field>
                        <input matInput [matDatepicker]="dp" [min]="minDate" formControlName="date">
                        <mat-datepicker-toggle matSuffix [for]="dp" id="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 align-self-center">
                    <input class="form-check-input" type="radio" formControlName="red_radio" value="true"
                        id="gridRadios1">
                </div>
                <label for="inputEmail3" class="col-sm-4 col-form-label">{{ "HOME.CHANGE_TO_RED" | translate }}</label>
            </div>
            <div class="form-group row" *ngIf="show_checkbox">
                <div class="col-sm-1">
                    <input type="checkbox" formControlName="delete_checkbox">
                </div>
                <div class="col-sm-10" style="color:red">Confirm and click Delete again</div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="Update_reminder(submitButton)" #submitButton>
            {{ "HOME.CONFIRMBUTTON" | translate }}
        </button>
        <button type="button" class="btn btn-outline-danger" style="display: initial;" (click)="deleteEvent()"> {{
            "HOME.REMOVEBUTTON" | translate }}</button>
        <button type="button" class="btn btn-success reset" (click)="modal.close('Save click')">
            {{ "HOME.CANCELBUTTON" | translate }}
        </button>


    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">
            {{ "HOME.ACTIVITYTITTLE" | translate }}
        </h2>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click'); clear_greendata()"></button>
    </div>
    <div class="modal-body">
        <p *ngIf="role == 'office'">
            {{ "HOME.ACTIVITYCONTENT_OFFICE" | translate }}
        </p>
        <p *ngIf="role == 'office'">
            {{ "HOME.ACTIVITY_QUES_OFFICE" | translate }} {{ green_eventdata?.orderID }}
            {{ "HOME.ON_WORKSTATION" | translate }} {{ name }} doen?
        </p>
        <p *ngIf="role != 'office'">
            {{ "HOME.ACTIVITYCONTENT" | translate }}
        </p>
        <p *ngIf="role != 'office'">
            {{ "HOME.ACTIVITY_QUES" | translate }} {{ green_eventdata.orderID }}
            {{ "HOME.ON_WORKSTATION" | translate }} {{ name }} ?
        </p>
        <form [formGroup]="activityForm" novalidate>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="event" formControlName="event" value="close"
                    id="flexRadioDefault1" />
                <label class="form-check-label" for="flexRadioDefault1">
                    {{ "HOME.CLOSEBUTTON" | translate }}
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="event" formControlName="event" value="yellow"
                    id="flexRadioDefault2" />
                <label class="form-check-label" for="flexRadioDefault2">
                    {{ "HOME.PUT_YELLOW" | translate }}
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="event" formControlName="event" value="red"
                    id="flexRadioDefault3" />
                <label class="form-check-label" for="flexRadioDefault3">
                    {{ "HOME.PUT_RED" | translate }}
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onSubmit_event()">
            {{ "HOME.CONFIRMBUTTON" | translate }}
        </button>
        <button type="button" class="btn btn-success reset" (click)="modal.close('Save click'); clear_greendata()">
            {{ "HOME.CANCELBUTTON" | translate }}
        </button>
    </div>
</ng-template>

<app-checklist-modal #instructions [attachments]="attachments" [checkListItems]="checkListItems"
    [checkListFormGroup]="checkListFormGroup" [goodsRecipt]="goodsReciptFlag"
    [orderID]="active_order?.orderID"></app-checklist-modal>

<ng-template #attachment let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">Document</h2>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
            Sluiten
        </button>
    </div>
    <div class="modal-body">
        <div class="row h-100">
            <div class="col-lg-2" style="border-right: 1px solid #eee">
                <span *ngFor="let itemss of attachments">
                    <button class="width-150px btn active pbm" aria-pressed="true" (click)="open_modal(itemss)">
                        {{ itemss.document_type }}
                    </button>
                    <br />
                </span>
            </div>
            <div class="col-lg-10 pdf-container">
                <iframe [src]="src" class="iframe" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #darkblueModal let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">
            {{ "HOME.ACTIVITYTITTLE" | translate }}
        </h2>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click');order_viewdisable=false;"></button>
    </div>
    <div class="modal-body">
        <h5>{{ "HOME.DARKBLUEEVENT_CONTENT" | translate }} {{darkblue_event[darkblue_event.length -
            1].eventDescription}} {{ "HOME.STOP" | translate }} ?</h5>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary confirm" (click)="submitHandler(submitButton)" #submitButton>
            {{ "HOME.CONFIRMBUTTON" | translate }}
        </button>
        <button type="button" class="btn btn-success reset"
            (click)="modal.close('Save click');order_viewdisable=false;">
            {{ "HOME.CANCELBUTTON" | translate }}
        </button>
    </div>
</ng-template>


<!-- <ng-template #content let-modal >
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Admin settings</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body mx-2">
        <form formGroup="registrationForm" novalidate>
              <div class="row g-3 align-items-center">
                <div class="col-auto">
                  <label for="inputPassword6" class="col-form-label">Deactivate Timer</label>
                </div>
                <div class="col-auto">
                  <input type="radio" name="timer" class="form-check-input" >
                  <label class="mx-2">Yes</label>
                </div>
                <div class="col-auto">
                    <input type="radio" name="timer" class="form-check-input" >
                    <label class="mx-2">No</label>
                  </div>
              </div> 
              <div class="row align-items-center">
                <div class="col-auto">
                  <label for="inputPassword6" class="col-form-label">Default language</label>
                </div>
                <div class="col-auto ">
                    <select class="form-select form-select-sm" id="lang" >
                        <option selected disabled>Select language </option>
                        <option value="1">English</option>
                        <option value="2">Dutch</option>
                        <option value="3">French</option>
                      </select>
                </div>
            
              </div> 
              <div class="row gap-1 align-items-center">
                <div class="col-auto">
                  <label for="inputPassword6" class="col-form-label">Maximum orders</label>
                </div>
                <div class="col-auto ">
                    <select class="form-select form-select-sm" id="lang" >
                        <option selected disabled>Select maximum order </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                </div>
              </div> 
        </form>
        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-primary" >To confirm</button>
    </div>
</ng-template> -->