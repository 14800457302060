<!--Instructions Modal-->
<ng-template #checklist let-modal>
  <div class="instructions-popup">
    <div class="modal-header">

      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button *ngIf="!isShow || this.attachments?.length == 0 || !this.attachments" type="button"
            class="btn print-btn" (click)="addChecklist('');modal.dismiss('Cross click')"> {{
            "HOME.CONFIRMBUTTON" | translate }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>
    <div class="modal-body ">
      <div class="instructions-content " *ngIf="workstationid == 98">
        <div class="instructions-content-left">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->

            <li><a [ngClass]="{
                bold: true
              }" (click)="setOption('checklist','');isD=true">{{ "HOME.CHECKLIST_LABEL" | translate }}</a></li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items);isD=false">
                  {{items.document_type}}</a></li>
            </ng-container>

          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">

          <ng-container *ngIf="!isShow">
            <form *ngIf="isD" novalidate (Submit)="addChecklist('')" [formGroup]="checkListFormGroup">
              <div *ngFor="let prop of checkListItems;">
                <div class="row mt-3">
                  <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
                    style="color:  #24597a;">{{prop.fieldName}}</label>

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input type="text" hidden="true" formControlName="details">
                      <textarea *ngSwitchCase="'textarea'" [ngClass]="{'textarea':prop.textarea}" class=" form-control"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''" name="" id="" cols="1"
                        [rows]="prop.addrows"></textarea>

                      <input *ngSwitchCase="'text'" class="form-control" [formControlName]="prop.fieldName"
                        [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                      <input *ngSwitchCase="'float'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'string'" class="form-control" [formControlName]="prop.fieldName"
                        [id]="prop.fieldName" type="varchar" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName"
                        [formControlName]="prop.fieldName">
                      <input *ngSwitchCase="'checkbox'"
                        [ngClass]="[(prop.protected === true || prop.protected == 'true' || +prop.protected === 1) ?'active':'in-active']"
                        class="form-check-input" [checked]="prop.value" type="checkbox" [id]="prop.fieldName"
                        [formControlName]="prop.fieldName">


                      <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <div class="input-group " *ngSwitchCase="'date'">
                        <input class="form-control dateicon" matInput [matDatepicker]="dp" (click)="dp.open()"
                          [id]="prop.key" [formControlName]="prop.fieldName">
                        <mat-datepicker-toggle class="date" matSuffix [for]="dp"
                          id="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName">



                      <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                      <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <mat-select class="form-control"
                        placeholder="{{prop['multi-select'] && prop.value[0]=='' && prop.options[0].id==''?prop.options[0].description:!(prop['multi-select']) && prop.value == '' && prop.options[0].id == ''?prop.options[0].description:''}}"
                        *ngSwitchCase="'select'" [(value)]="prop.value" multiple="{{prop['multi-select']}}"
                        [formControlName]="prop.fieldName">
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id !=''">

                            {{list.description}}

                          </mat-option>

                        </ng-container>
                      </mat-select>

                    </div>
                  </ng-container>
                </div>
              </div>
              <br>
            </form>


          </ng-container>

          <ng-container *ngIf="!isD">
            <div class="row h-100"
              *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
              </ng-container>
            </div>
          </ng-container>

        </div>
      </div>
      <div class="instructions-content " *ngIf="workstationid != 98">
        <div class="instructions-content-left">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->

            <li><a [ngClass]="{
                bold: true
              }" (click)="setOption('checklist','');">{{ "HOME.CHECKLIST_LABEL" | translate }}</a></li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items);">{{items.document_type}}</a></li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <ng-container>
            <form novalidate (Submit)="addChecklist('')" [formGroup]="checkListFormGroup">
              <div *ngFor="let prop of checkListItems;">
                <div class="row mt-3">
                  <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
                    style="color:  #24597a;">{{prop.fieldName}}</label>

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input type="text" hidden="true" formControlName="details">
                      <textarea *ngSwitchCase="'textarea'" [ngClass]="{'textarea':prop.textarea}" class=" form-control"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''" name="" id="" cols="1"
                        [rows]="prop.addrows"></textarea>

                      <input *ngSwitchCase="'text'" class="form-control" [formControlName]="prop.fieldName"
                        [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                      <input *ngSwitchCase="'float'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'string'" class="form-control" [formControlName]="prop.fieldName"
                        [id]="prop.fieldName" type="varchar" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName"
                        [formControlName]="prop.fieldName">
                      <input *ngSwitchCase="'checkbox'"
                        [ngClass]="[(prop.protected === true || prop.protected == 'true' || +prop.protected === 1) ?'active':'in-active']"
                        class="form-check-input" [checked]="prop.value" type="checkbox" [id]="prop.fieldName"
                        [formControlName]="prop.fieldName">


                      <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <div class="input-group " *ngSwitchCase="'date'">
                        <input class="form-control dateicon" matInput [matDatepicker]="dp" (click)="dp.open()"
                          [id]="prop.key" [formControlName]="prop.fieldName">
                        <mat-datepicker-toggle class="date" matSuffix [for]="dp"
                          id="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName">



                      <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                      <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <mat-select class="form-control"
                        placeholder="{{prop['multi-select'] && prop.value[0]=='' && prop.options[0].id==''?prop.options[0].description:!(prop['multi-select']) && prop.value == '' && prop.options[0].id == ''?prop.options[0].description:''}}"
                        *ngSwitchCase="'select'" [(value)]="prop.value" multiple="{{prop['multi-select']}}"
                        [formControlName]="prop.fieldName">
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id !=''">

                            {{list.description}}

                          </mat-option>

                        </ng-container>
                      </mat-select>

                    </div>
                  </ng-container>
                </div>
              </div>
              <br>
            </form>


          </ng-container>

          <ng-container>
            <div class="row h-100"
              *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
              </ng-container>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #checklisttop let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross clicked');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button *ngIf="!isShow || this.attachments?.length == 0 || !this.attachments" type="button"
            class="btn print-btn" (click)="aaddChecklist('');modal.dismiss('Cross clicked')"> {{
            "HOME.CONFIRMBUTTON" | translate }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross clicked')"></button>
        </div>
      </div>
    </div>
    <div class="modal-body ">
      <div class="instructions-content " *ngIf="workstationid == 98">
        <div class="instructions-content-left">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->

            <li><a [ngClass]="{
                bold: true
              }" (click)="setOption('checklist','');isD=true">{{ "HOME.CHECKLIST_LABEL" | translate }}</a></li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items);isD=false">
                  {{items.document_type}}</a></li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <ng-container *ngIf="!isShow">
            <form *ngIf="isD" novalidate (Submit)="aaddChecklist('')" [formGroup]="checklistFormList">
              <div *ngFor="let prop of check_List;">
                <div class="row mt-3">
                  <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
                    style="color:  #24597a;">{{prop.fieldName}}</label>

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input type="text" hidden="true" formControlName="details">
                      <textarea *ngSwitchCase="'textarea'" [ngClass]="{'textarea':prop.textarea}" class=" form-control"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''" name="" id="" cols="1"
                        [rows]="prop.addrows"></textarea>

                      <input *ngSwitchCase="'text'" class="form-control" [formControlName]="prop.fieldName"
                        [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                      <input *ngSwitchCase="'float'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'string'" class="form-control" [formControlName]="prop.fieldName"
                        [id]="prop.fieldName" type="varchar" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName"
                        [formControlName]="prop.fieldName">
                      <input *ngSwitchCase="'checkbox'" class="form-check-input" [checked]="prop.value"
                        [ngClass]="[(prop.protected === true || prop.protected == 'true' || +prop.protected === 1) ?'active':'in-active']"
                        type="checkbox" [id]="prop.fieldName" [formControlName]="prop.fieldName">


                      <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <div class="input-group " *ngSwitchCase="'date'">
                        <input class="form-control dateicon" matInput [matDatepicker]="dp" (click)="dp.open()"
                          [id]="prop.key" [formControlName]="prop.fieldName">
                        <mat-datepicker-toggle class="date" matSuffix [for]="dp"
                          id="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName">



                      <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                      <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <mat-select class="form-control"
                        placeholder="{{prop['multi-select'] && prop.value[0]=='' && prop.options[0].id==''?prop.options[0].description:!(prop['multi-select']) && prop.value == '' && prop.options[0].id == ''?prop.options[0].description:''}}"
                        *ngSwitchCase="'select'" [(value)]="prop.value" multiple="{{prop['multi-select']}}"
                        [formControlName]="prop.fieldName">
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id !=''">

                            {{list.description}}

                          </mat-option>

                        </ng-container>
                      </mat-select>

                    </div>
                  </ng-container>
                </div>
              </div>
              <br>
            </form>


          </ng-container>

          <ng-container *ngIf="!isD">
            <div class="row h-100"
              *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
              </ng-container>

              <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->

            </div>
          </ng-container>

        </div>
      </div>
      <div class="instructions-content " *ngIf="workstationid != 98">
        <div class="instructions-content-left">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->

            <li><a [ngClass]="{
                bold: true
              }" (click)="setOption('checklist','');">{{ "HOME.CHECKLIST_LABEL" | translate }}</a></li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items);">{{items.document_type}}</a></li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <ng-container>
            <form novalidate (Submit)="aaddChecklist('')" [formGroup]="checklistFormList">
              <div *ngFor="let prop of check_List;">
                <div class="row mt-3">
                  <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
                    style="color:  #24597a;">{{prop.fieldName}}</label>

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input type="text" hidden="true" formControlName="details">
                      <textarea *ngSwitchCase="'textarea'" [ngClass]="{'textarea':prop.textarea}" class=" form-control"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''" name="" id="" cols="1"
                        [rows]="prop.addrows"></textarea>

                      <input *ngSwitchCase="'text'" class="form-control" [formControlName]="prop.fieldName"
                        [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                      <input *ngSwitchCase="'float'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                        [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'string'" class="form-control" [formControlName]="prop.fieldName"
                        [id]="prop.fieldName" type="varchar" [value]="prop?.value"
                        [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName"
                        [formControlName]="prop.fieldName">
                      <input *ngSwitchCase="'checkbox'" class="form-check-input"
                        [ngClass]="[(prop.protected === true || prop.protected == 'true' || +prop.protected === 1) ?'active':'in-active']"
                        [checked]="prop.value" type="checkbox" [id]="prop.fieldName" [formControlName]="prop.fieldName">


                      <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <div class="input-group " *ngSwitchCase="'date'">
                        <input class="form-control dateicon" matInput [matDatepicker]="dp" (click)="dp.open()"
                          [id]="prop.key" [formControlName]="prop.fieldName">
                        <mat-datepicker-toggle class="date" matSuffix [for]="dp"
                          id="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName">



                      <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                      <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [value]="prop?.value"
                        [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                      <mat-select class="form-control"
                        placeholder="{{prop['multi-select'] && prop.value[0]=='' && prop.options[0].id==''?prop.options[0].description:!(prop['multi-select']) && prop.value == '' && prop.options[0].id == ''?prop.options[0].description:''}}"
                        *ngSwitchCase="'select'" [(value)]="prop.value" multiple="{{prop['multi-select']}}"
                        [formControlName]="prop.fieldName">
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id !=''">

                            {{list.description}}

                          </mat-option>

                        </ng-container>
                      </mat-select>

                    </div>
                  </ng-container>
                </div>
              </div>
              <br>
            </form>


          </ng-container>

          <ng-container>
            <div class="row h-100"
              *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
              </ng-container>
              <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #onlyChecklist let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button type="button" class="btn print-btn" (click)="addChecklist('');modal.dismiss('Cross click')"> {{
            "HOME.CONFIRMBUTTON" | translate }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>

    <div class="modal-body ">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li><a class=" bold" (click)="setOption('checklist','');">{{ "HOME.CHECKLIST_LABEL" |
                translate }}</a>
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items);isD=false">{{items.document_type}}</a></li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <form novalidate [formGroup]="checkListFormGroup">
            <div *ngFor="let prop of checkListItems;">
              <div class="row mt-3">
                <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
                  style="color:  #24597a;">{{prop.fieldName}}</label>

                <ng-container [ngSwitch]="prop.fieldType">
                  <div class="col-sm-8">
                    <input type="text" hidden="true" formControlName="details">
                    <textarea *ngSwitchCase="'textarea'" [ngClass]="{'textarea':prop.textarea}" class="form-control "
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''" name="" id="" cols="1"
                      [rows]="prop.addrows"></textarea>
                    <input *ngSwitchCase="'text'" class="form-control" [formControlName]="prop.fieldName"
                      [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                    <input *ngSwitchCase="'float'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'string'" class="form-control" [formControlName]="prop.fieldName"
                      [id]="prop.fieldName" type="varchar" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName" [formControlName]="prop.fieldName">
                    <input *ngSwitchCase="'checkbox'"
                      [ngClass]="[(prop.protected === true || prop.protected == 'true' || +prop.protected === 1) ?'active':'in-active']"
                      class="form-check-input" [checked]="prop.value" type="checkbox" [id]="prop.fieldName"
                      [formControlName]="prop.fieldName">


                    <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <div class="input-group " *ngSwitchCase="'date'">
                      <input class="form-control dateicon" matInput [matDatepicker]="dp" (click)="dp.open()"
                        [id]="prop.key" [formControlName]="prop.fieldName">
                      <mat-datepicker-toggle class="date" matSuffix [for]="dp" id="datepicker"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </div>

                    <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName">



                    <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                    <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <mat-select class="form-control"
                      placeholder="{{prop['multi-select'] && prop.value[0]=='' && prop.options[0].id==''?prop.options[0].description:!(prop['multi-select']) && prop.value == '' && prop.options[0].id == ''?prop.options[0].description:''}}"
                      *ngSwitchCase="'select'" [(value)]="prop.value" multiple="{{prop['multi-select']}}"
                      [formControlName]="prop.fieldName">
                      <ng-container *ngFor="let list of prop.options">
                        <mat-option [value]="list.id" *ngIf="list.id !=''">

                          {{list.description}}

                        </mat-option>

                      </ng-container>
                    </mat-select>

                  </div>
                </ng-container>
              </div>
            </div>
            <br>
          </form>


          <ng-container *ngIf="!isD">
            <div class="row h-100"
              *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>
              </ng-container>

              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-doc-viewer [url]="src" viewer="google">
                </ngx-doc-viewer> -->
                <!-- <a [href]="src" target="_blank">Open pdf</a> -->
                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->

                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
              </ng-container>
            </div>
          </ng-container>
        </div>


      </div>
    </div>
  </div>
</ng-template>
<ng-template #instructions let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button type="button" class="btn print-btn" [ngClass]="{'disabled-btn disabled': true}"
            (click)="addChecklist('');modal.dismiss('Cross click')"> {{
            "HOME.CONFIRMBUTTON" | translate }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>
    <div class="modal-body ">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: true
              }" (click)="setOption('instructions','')">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items)">{{items.document_type}}</a></li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">

          <div class="row">
            <div class="mt-3" *ngFor="let item of instructionsItems ">
              <label class="col-sm-8 instLabel">{{item?.fieldDescription}}</label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'checkbox' && item?.value == null"><input
                  class="form-check-input" type="checkbox" disabled></label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'text' && item?.value == null"></label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'checkbox' && item?.value != null"><input
                  class="form-check-input" type="checkbox" checked disabled></label>
              <label class="col-sm-4 instLabel"
                *ngIf="(item.fieldType == 'text') && item?.value != null">{{item?.value}}</label>
            </div>
          </div>
          <div class="row h-100"
            *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->

              <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
            </ng-container>
            <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #checklistinstructions let-modal>
  <div class="instructions-popup">
    <div class="modal-header" *ngIf="!isShow">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button type="button" class="btn print-btn" (click)="addChecklist('');modal.dismiss('Cross click')"> {{
            "HOME.CONFIRMBUTTON" | translate }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>
    <div class="modal-header" *ngIf="isShow">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button type="button" class="btn print-btn" [ngClass]="{'disabled-btn disabled': true}"
            (click)="addChecklist('');modal.dismiss('Cross click')"> {{
            "HOME.CONFIRMBUTTON" | translate }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>
    <div class="modal-body ">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="isShow?'chacive':''" class="checklisinstruction bold"
                (click)="setOption('instructions','');isShow=true">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li>
            <li *ngIf="workstationid==98"><a class="checklisinstruction bold" [ngClass]="!isShow?'chacive':''"
                (click)="setOption('checklist','');isD=true;this.secondOrderId=''">{{ "HOME.CHECKLIST_LABEL" |
                translate }}</a></li>
            <li *ngIf="workstationid != 98"><a class="checklisinstruction bold" [ngClass]="!isShow?'chacive':''"
                (click)="setOption('checklist','');isShow=false;this.secondOrderId=''">{{ "HOME.CHECKLIST_LABEL" |
                translate }}</a></li>


            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items);isD=false">{{items.document_type}}</a></li>
            </ng-container>
          </ul>
        </div>
        <div *ngIf="!isShow" class="projectbody instructions-content-right-desktop">
          <form *ngIf="isD" novalidate (Submit)="addChecklist('')" [formGroup]="checkListFormGroup">
            <div *ngFor="let prop of checkListItems;">
              <div class="row mt-3">
                <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
                  style="color:  #24597a;">{{prop.fieldName}}</label>

                <ng-container [ngSwitch]="prop.fieldType">
                  <div class="col-sm-8">
                    <input type="text" hidden="true" formControlName="details">
                    <textarea *ngSwitchCase="'textarea'" [ngClass]="{'textarea':prop.textarea}" class="form-control "
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''" name="" id="" cols="1"
                      [rows]="prop.addrows"></textarea>
                    <input *ngSwitchCase="'text'" class="form-control" [formControlName]="prop.fieldName"
                      [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                    <input *ngSwitchCase="'float'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'string'" class="form-control" [formControlName]="prop.fieldName"
                      [id]="prop.fieldName" type="varchar" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName" [formControlName]="prop.fieldName">
                    <input *ngSwitchCase="'checkbox'"
                      [ngClass]="[(prop.protected === true || prop.protected == 'true' || +prop.protected === 1) ?'active':'in-active']"
                      class="form-check-input" [checked]="prop.value" type="checkbox" [id]="prop.fieldName"
                      [formControlName]="prop.fieldName">


                    <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <div class="input-group " *ngSwitchCase="'date'">
                      <input class="form-control dateicon" matInput [matDatepicker]="dp" (click)="dp.open()"
                        [id]="prop.key" [formControlName]="prop.fieldName">
                      <mat-datepicker-toggle class="date" matSuffix [for]="dp" id="datepicker"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </div>

                    <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName">



                    <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                    <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <mat-select class="form-control"
                      placeholder="{{prop['multi-select'] && prop.value[0]=='' && prop.options[0].id==''?prop.options[0].description:!(prop['multi-select']) && prop.value == '' && prop.options[0].id == ''?prop.options[0].description:''}}"
                      *ngSwitchCase="'select'" [(value)]="prop.value" multiple="{{prop['multi-select']}}"
                      [formControlName]="prop.fieldName">
                      <ng-container *ngFor="let list of prop.options">
                        <mat-option [value]="list.id" *ngIf="list.id !=''">

                          {{list.description}}

                        </mat-option>

                      </ng-container>
                    </mat-select>

                  </div>
                </ng-container>
              </div>
            </div>
            <br>
          </form>


          <ng-container *ngIf="!isD">
            <div class="row h-100"
              *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>
              </ng-container>

              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-doc-viewer [url]="src" viewer="google">
                </ngx-doc-viewer> -->
                <!-- <a [href]="src" target="_blank">Open pdf</a> -->
                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->

                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div *ngIf="isShow" class="projectbody instructions-content-right-desktop">

          <div class="row">
            <div class="mt-3" *ngFor="let item of instructionsItems ">
              <label class="col-sm-8 instLabel">{{item?.fieldDescription}}</label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'checkbox' && item?.value == null"><input
                  class="form-check-input" type="checkbox" disabled></label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'text' && item?.value == null"></label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'checkbox' && item?.value != null"><input
                  class="form-check-input" type="checkbox" checked disabled></label>
              <label class="col-sm-4 instLabel"
                *ngIf="(item.fieldType == 'text') && item?.value != null">{{item?.value}}</label>
            </div>
          </div>
          <div class="row h-100"
            *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
            </ng-container>
          </div>
        </div>

      </div>
    </div>
  </div>

</ng-template>
<ng-template #topinstructionchecklist let-modal>
  <div class="instructions-popup" style="overflow: auto;">
    <div class="modal-header">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          </button>
        </div>
      </div>
    </div>

    <div class="modal-body ">
      <div class="instructions-content justify-content-between">
        <div class="instructions-content-left instructions-content-right-desktop-deep">
          <ul>
            <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: true
              }" (click)="setOption('instructions','')">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li>

            <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items)">{{items.document_type}}</a></li>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop instructions-content-right-desktop-deep">
          <div class="modal-header modal-header-acn ">
            <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
              <h2 class="modal-title" style="color: #24597A;"></h2>
              <div class="header-actions">

                <button type="button" class="btn print-btn" [ngClass]="{'disabled-btn disabled': true}"
                  (click)="addChecklist('');;modal.dismiss('Cross click')"> {{
                  "HOME.CONFIRMBUTTON" | translate }}</button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="mt-3" *ngFor="let item of instructionsItems ">
              <label class="col-sm-8 instLabel">{{item?.fieldDescription}}</label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'checkbox' && item?.value == null"><input
                  class="form-check-input" type="checkbox" disabled></label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'text' && item?.value == null"></label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'checkbox' && item?.value != null"><input
                  class="form-check-input" type="checkbox" checked disabled></label>
              <label class="col-sm-4 instLabel"
                *ngIf="(item.fieldType == 'text') && item?.value != null">{{item?.value}}</label>
            </div>
          </div>
          <div class="row h-100"
            *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
            </ng-container>
          </div>
        </div>
      </div>
    </div>



    <div class="modal-header p-0"></div>
    <div class="modal-body ">
      <div class="instructions-content justify-content-between">
        <div class="instructions-content-left instructions-content-right-desktop-deep">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->
            <li><a [ngClass]="{
                bold: true
              }" (click)="setOption('checklist','')">{{ "HOME.CHECKLIST_LABEL" | translate }}</a></li>
            <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items)">{{items.document_type}}</a></li>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop instructions-content-right-desktop-deep">
          <div class="modal-header modal-header-acn">
            <div class="d-flex align-items-center justify-content-end" style="width: 100%;">
              <!-- <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2> -->
              <div class="header-actions">
                <!-- <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
                  (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
                <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
                  "HOME.CANCELBUTTON" | translate
                  }}</button> -->
                <button type="button" class="btn print-btn" (click)="addChecklist('')"> {{
                  "HOME.CONFIRMBUTTON" | translate }}</button>
                <!-- <button type="button" class="btn-close" aria-label="Close"
                  (click)="modal.dismiss('Cross click')"></button> -->
              </div>
            </div>
          </div>
          <form novalidate (Submit)="addChecklist('')" [formGroup]="checkListFormGroup">
            <div *ngFor="let prop of checkListItems;">
              <div class="row mt-3">
                <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
                  style="color:  #24597a;">{{prop.fieldName}}</label>

                <ng-container [ngSwitch]="prop.fieldType">
                  <div class="col-sm-8">
                    <input type="text" hidden="true" formControlName="details">
                    <textarea *ngSwitchCase="'textarea'" [ngClass]="{'textarea':prop.textarea}" class=" form-control"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''" name="" id="" cols="1"
                      [rows]="prop.addrows"></textarea>

                    <input *ngSwitchCase="'text'" class="form-control" [formControlName]="prop.fieldName"
                      [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                    <input *ngSwitchCase="'float'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'string'" class="form-control" [formControlName]="prop.fieldName"
                      [id]="prop.fieldName" type="varchar" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName" [formControlName]="prop.fieldName">
                    <input *ngSwitchCase="'checkbox'"
                      [ngClass]="[(prop.protected === true || prop.protected == 'true' || +prop.protected === 1) ?'active':'in-active']"
                      class="form-check-input" [checked]="prop.value" type="checkbox" [id]="prop.fieldName"
                      [formControlName]="prop.fieldName">


                    <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <div class="input-group " *ngSwitchCase="'date'">
                      <input class="form-control dateicon" matInput [matDatepicker]="dp" (click)="dp.open()"
                        [id]="prop.key" [formControlName]="prop.fieldName">
                      <mat-datepicker-toggle class="date" matSuffix [for]="dp" id="datepicker"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </div>

                    <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName">



                    <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                    <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <mat-select class="form-control"
                      placeholder="{{prop['multi-select'] && prop.value[0]=='' && prop.options[0].id==''?prop.options[0].description:!(prop['multi-select']) && prop.value == '' && prop.options[0].id == ''?prop.options[0].description:''}}"
                      *ngSwitchCase="'select'" [(value)]="prop.value" multiple="{{prop['multi-select']}}"
                      [formControlName]="prop.fieldName">
                      <ng-container *ngFor="let list of prop.options">
                        <mat-option [value]="list.id" *ngIf="list.id !=''">

                          {{list.description}}

                        </mat-option>

                      </ng-container>
                    </mat-select>

                  </div>
                </ng-container>
              </div>
            </div>
            <br>
          </form>

          <div class="row h-100"
            *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
            </ng-container>
          </div>
        </div>
      </div>
    </div>

  </div>

</ng-template>

<ng-template #instructionanddocuments let-modal>
  <div class="instructions-popup">
    <div class="modal-header" *ngIf="!isShoww">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button type="button" class="btn print-btn" (click)="addChecklist('');modal.dismiss('Cross click')"> {{
            "HOME.CONFIRMBUTTON" | translate }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>
    <div class="modal-header" *ngIf="isShoww">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2>
        <div class="header-actions">
          <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
            (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
          <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
            "HOME.CANCELBUTTON" | translate
            }}</button>
          <button type="button" class="btn print-btn" [ngClass]="{'disabled-btn disabled': true}"
            (click)="addChecklist('');modal.dismiss('Cross click')"> {{
            "HOME.CONFIRMBUTTON" | translate }}</button>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>
    <div class="modal-body ">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')|| true">
              <a [ngClass]="isShoww?'chacive':''" class="checklisinstruction bold"
                (click)="setOption('instructions','');isShoww=true;isD=true;">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li>
            <li><a class="checklisinstruction bold" [ngClass]="!isShoww?'chacive':''"
                (click)="setOption('checklist','');isShoww=false;isD=true;this.secondOrderId=''">{{
                "HOME.CHECKLIST_LABEL" |
                translate }}</a></li>



            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments"><a [ngClass]="{
                bold: selectedOption == items.document_type
              }" (click)="setOption(items.document_type,items);isD=false;isShoww=false;">{{items.document_type}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
        <div *ngIf="!isShoww" class="projectbody instructions-content-right-desktop">
          <form *ngIf="isD" novalidate (Submit)="addChecklist('')" [formGroup]="checkListFormGroup">
            <div *ngFor="let prop of checkListItems;">
              <div class="row mt-3">
                <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
                  style="color:  #24597a;">{{prop.fieldName}}</label>

                <ng-container [ngSwitch]="prop.fieldType">
                  <div class="col-sm-8">
                    <input type="text" hidden="true" formControlName="details">
                    <textarea *ngSwitchCase="'textarea'" [ngClass]="{'textarea':prop.textarea}" class="form-control "
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''" name="" id="" cols="1"
                      [rows]="prop.addrows"></textarea>
                    <input *ngSwitchCase="'text'" class="form-control" [formControlName]="prop.fieldName"
                      [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                    <input *ngSwitchCase="'float'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                      [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'string'" class="form-control" [formControlName]="prop.fieldName"
                      [id]="prop.fieldName" type="varchar" [value]="prop?.value"
                      [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName" [formControlName]="prop.fieldName">
                    <input *ngSwitchCase="'checkbox'" [ngClass]="[prop.protected === true?'active':'in-active']"
                      class="form-check-input" [checked]="prop.value" type="checkbox" [id]="prop.fieldName"
                      [formControlName]="prop.fieldName">


                    <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <div class="input-group " *ngSwitchCase="'date'">
                      <input class="form-control dateicon" matInput [matDatepicker]="dp" (click)="dp.open()"
                        [id]="prop.key" [formControlName]="prop.fieldName">
                      <mat-datepicker-toggle class="date" matSuffix [for]="dp" id="datepicker"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </div>

                    <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName">



                    <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


                    <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [value]="prop?.value"
                      [formControlName]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''">

                    <mat-select class="form-control"
                      placeholder="{{prop['multi-select'] && prop.value[0]=='' && prop.options[0].id==''?prop.options[0].description:!(prop['multi-select']) && prop.value == '' && prop.options[0].id == ''?prop.options[0].description:''}}"
                      *ngSwitchCase="'select'" [(value)]="prop.value" multiple="{{prop['multi-select']}}"
                      [formControlName]="prop.fieldName">
                      <ng-container *ngFor="let list of prop.options">
                        <mat-option [value]="list.id" *ngIf="list.id !=''">

                          {{list.description}}

                        </mat-option>

                      </ng-container>
                    </mat-select>

                  </div>
                </ng-container>
              </div>
            </div>
            <br>
          </form>


          <ng-container *ngIf="!isD">
            <div class="row h-100"
              *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>
              </ng-container>

              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-doc-viewer [url]="src" viewer="google">
                </ngx-doc-viewer> -->
                <!-- <a [href]="src" target="_blank">Open pdf</a> -->
                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->

                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div *ngIf="isShoww" class="projectbody instructions-content-right-desktop">

          <div class="row">
            <div class="mt-3" *ngFor="let item of instructionsItems ">
              <label class="col-sm-8 instLabel">{{item?.fieldDescription}}</label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'checkbox' && item?.value == null"><input
                  class="form-check-input" type="checkbox" disabled></label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'text' && item?.value == null"></label>
              <label class="col-sm-4 instLabel" *ngIf="item.fieldType == 'checkbox' && item?.value != null"><input
                  class="form-check-input" type="checkbox" checked disabled></label>
              <label class="col-sm-4 instLabel"
                *ngIf="(item.fieldType == 'text') && item?.value != null">{{item?.value}}</label>
            </div>
          </div>
          <div class="row h-100"
            *ngIf="(selectedOption != 'instructions' && selectedOption != 'checklist' ) && attachments">
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe>

              <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->
            </ng-container>
          </div>
        </div>

      </div>
    </div>
  </div>

</ng-template>


<!--Modal-->
<ng-template #content let-modal>
  <div class="modal-body Order-line-popup">
    <form [formGroup]="productForm" novalidate>
      <div class="Order-line-block d-flex justify-content-between align-items-center">
        <label>{{ "HOME.QUANTITY_LABEL" | translate }}</label>
        <div class="input-group">
          <input type="number" class="form-control order-input" min="1" formControlName="quantity">
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center popup-actions">
        <button class="btn next-button" (click)="postPrintDetails('1')"> {{ "HOME.CONFIRMBUTTON" | translate }}</button>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #extrainputmodal let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body Order-line-popup">
    <form novalidate (keyup.enter)="addChecklist('small')" [formGroup]="checkListFormGroup"
      *ngIf="selectedOption == 'checklist'">
      <div *ngFor="let prop of checkListItems">
        <div class="row mt-3">
          <label [attr.for]="prop.fieldName" class="col-sm-4 d-flex p-2"
            style="color:  #24597a;">{{prop.fieldName}}</label>

          <ng-container [ngSwitch]="prop.fieldType">
            <div class="col-sm-8">
              <input type="text" hidden="true" formControlName="details">

              <input [ngClass]="errorMsg? 'errormsg':''" *ngSwitchCase="'text'" class="form-control"
                [name]="prop.fieldName" [placeholder]="(prop?.placeholder)?prop?.placeholder:''"
                [formControlName]="prop.fieldName" [id]="prop.fieldName" [type]="prop.fieldType" [value]="prop?.value">

              <input *ngSwitchCase="'int'" class="form-control" pattern="[0-9]*" inputmode="numeric"
                [placeholder]="(prop?.placeholder)?prop?.placeholder:''" [name]="prop.fieldName"
                [formControlName]="prop.fieldName" [id]="prop.fieldName" type="number" [value]="prop?.value">


              <input *ngSwitchCase="'string'" class="form-control" [name]="prop.fieldName"
                [placeholder]="(prop?.placeholder)?prop?.placeholder:''" [formControlName]="prop.fieldName"
                [id]="prop.fieldName" type="varchar" [value]="prop?.value">


              <input *ngSwitchCase="'file'" type="file" [id]="prop.fieldName" [name]="prop.fieldName"
                [formControlName]="prop.fieldName">
              <input *ngSwitchCase="'checkbox'"
                [ngClass]="[(prop.protected === true || prop.protected == 'true' || +prop.protected === 1) ?'active':'in-active']"
                class="form-check-input" [name]="prop.fieldName" [checked]="prop.value" type="checkbox"
                [id]="prop.fieldName" [formControlName]="prop.fieldName">



              <input *ngSwitchCase="'email'" type="email" [id]="prop.fieldName" [name]="prop.fieldName"
                [value]="prop?.value" [formControlName]="prop.fieldName"
                [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


              <div class=" input-group " *ngSwitchCase="'date'">
                <input class="form-control dateicon" matInput [matDatepicker]="dp" [id]="prop.key"
                  [name]="prop.fieldName" [formControlName]="prop.fieldName">
                <mat-datepicker-toggle class="date" matSuffix [for]="dp" id="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
              </div>


              <input *ngSwitchCase="'datetime'" type="datetime" [id]="prop.fieldName" [name]="prop.fieldName"
                [value]="prop?.value" [formControlName]="prop.fieldName">

              <input *ngSwitchCase="'reset'" type="reset" [id]="prop.fieldName" [name]="prop.fieldName"
                [value]="prop?.value" [formControlName]="prop.fieldName"
                [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


              <input *ngSwitchCase="'tel'" type="tel" [id]="prop.fieldName" [name]="prop.fieldName"
                [value]="prop?.value" [formControlName]="prop.fieldName"
                [placeholder]="(prop?.placeholder)?prop?.placeholder:''">


              <input *ngSwitchCase="'url'" type="url" [id]="prop.fieldName" [name]="prop.fieldName"
                [value]="prop?.value" [formControlName]="prop.fieldName"
                [placeholder]="(prop?.placeholder)?prop?.placeholder:''">



            </div>
          </ng-container>
        </div>
      </div>
      <br>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark cancelbtn" (click)="modal.close('Save click')">
      {{ "HOME.CANCELBUTTON" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="addChecklist('small')">
      {{ "HOME.CONFIRMBUTTON" | translate }}
    </button>
  </div>
</ng-template>