<div class="container">
    <div class="order-wrapper" *ngIf="CustomerScreen">
        <div class="d-flex justify-content-between align-items-center order-wrapper-header">
            <h1> {{ "HOME.CREATE_ORDER" | translate }} : {{ "HOME.SELECT_CUSTOMER" | translate }}</h1>
            <div class="search-bar">
                <input type="text" class="form-control" [(ngModel)]="SearchText" />
                <i class="fa fa-search" style="color: #24597A;"></i>
            </div>
        </div>
        <div class="accordion-block">
            <ngb-accordion [closeOthers]="true" [activeIds]="openedId" #acc="ngbAccordion"
                (panelChange)="customerInfo($event)" class="custom-accordion">
                <ng-container *ngFor="let item of CustomerList | filter:SearchText">
                    <ngb-panel [id]="item.CustomerID" [title]="item.CustomerID+' - '+item.Klantnaam" class="accordion">
                        <ng-template ngbPanelContent>
                            <p><b>{{ "HOME.ADDRESS_LABEL" | translate }} </b> : {{item?.Streetname}}, {{item?.Zipcode}}
                                {{item?.Plaats}} ({{item?.Country}})</p>
                        </ng-template>
                    </ngb-panel>
                </ng-container>
            </ngb-accordion>
        </div>
    </div>
    <!--Create Production Order-->
    <div class="create-production-order-wrapper" *ngIf="ProductionScreen">
        <div class="d-flex justify-content-between align-items-center production-order-header">
            <h1 *ngIf="!orderData">{{ "HOME.CREATE_PRODUCTION_ORDER" | translate }}</h1>
            <h1 *ngIf="orderData">{{ "HOME.EDIT_PRODUCTION_ORDER" | translate }} {{orderData?.orderID}}</h1>
            <div class="form-input">
                <input type="text" class="form-control" [(ngModel)]="selectedCustomer.Klantnaam" disabled />
            </div>
        </div>
        <form [formGroup]="productionOrderForm" novalidate>
            <div class="production-order-form">
                <div class="production-order-block">
                    <label>{{ "HOME.PALLETS_LABEL" | translate }}</label>
                    <div class="input-group">
                        <input type="number" #elem class="form-control" id="palletId" formControlName="pallets"
                            (touchend)="handleTouch($event,'1')">
                    </div>
                </div>
                <div class="production-order-block">
                    <label>{{ "HOME.DELIVERY_DATE" | translate }}</label>
                    <div class=" input-group ">
                        <input class="form-control" #dpInput matInput [matDatepicker]="dp" (click)="dp.open()"
                            (touchend)="handleTouch($event,'2')" (focus)="dp?.open()" formControlName="deliveryDate">
                        <mat-datepicker-toggle class="date" matSuffix [for]="dp"
                            id="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                    </div>
                </div>
                <div class="production-order-block">
                    <label>{{ "HOME.READY_DATE" | translate }}</label>
                    <div class=" input-group ">
                        <input class="form-control" #readyInput matInput [matDatepicker]="readyDatedp"
                            (click)="readyDatedp.open()" (touchend)="handleTouch($event,'3')"
                            (focus)="readyDatedp?.open()" formControlName="readyDate">
                        <mat-datepicker-toggle class="date" matSuffix [for]="readyDatedp"
                            id="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #readyDatedp></mat-datepicker>
                    </div>
                </div>
                <div class="production-order-block">
                    <label>{{ "HOME.CUSTOMER_REFERENCE" | translate }}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" #reference formControlName="customerRef"
                            id="referenceId" (touchend)="handleTouch($event,'4')">
                    </div>
                </div>




                <div class="divider"></div>
                <div class="production-order-form-action d-flex justify-content-between align-items-center">
                    <h1>{{ "HOME.ORDER_LINE" | translate }}</h1>
                    <button type="button" class="btn next-button" id="nextButtonId"
                        (click)="openOrderLinePopup(content, null)" (touchend)="openOrderLinePopup(content, null)">
                        <i class="fa fa-plus-circle" style="margin-right: 10px;"></i>
                        {{ "HOME.ADD_ORDER_LINE" | translate }}
                    </button>
                </div>
                <div class="order-line-table" *ngIf="orderLineList?.length">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Product</th>
                                <th>{{ "HOME.QTY_SHORT" | translate }}</th>
                                <th>{{ "HOME.DESCRIPTION_LABEL" | translate }}</th>
                                <th *ngIf="selectedCustomer.batch_on_orderline == '1'">{{ "HOME.BATCH_NUMBERS" |
                                    translate }}</th>
                                <th>{{ "HOME.ACTION_LABEL" | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let info of orderLineList; let i = index">
                                <tr>
                                    <td (click)="openOrderLinePopup(content, i)">{{info.item}}</td>
                                    <td (click)="openOrderLinePopup(content, i)">{{info.productId}}</td>
                                    <td (click)="openOrderLinePopup(content, i)">{{info.quantity}}</td>
                                    <td (click)="openOrderLinePopup(content, i)">{{info.description}}</td>
                                    <td *ngIf="selectedCustomer.batch_on_orderline == '1'"
                                        (click)="openOrderLinePopup(content, i)"
                                        (touchend)="openOrderLinePopup(content, i)">{{ info.batch_codes }}</td>
                                    <td class="lightblue">
                                        <div class=" drowdown">
                                            <a class="btn action-dropdown" href="#" role="button" id="orderDropdown"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="fa fa-angle-down"></i>
                                            </a>
                                            <ul class="dropdown-menu custom-dropdown dropdown-menu-end"
                                                aria-labelledby="orderDropdown">
                                                <li>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        (click)="openOrderLinePopup(content, i)"
                                                        (touchend)="openOrderLinePopup(content, i)"><img
                                                            src="../../assets/edit-order.svg" />{{ "HOME.EDIT_LABEL" |
                                                        translate }}</a>
                                                </li>
                                                <li *ngIf="orderData">
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        (click)="openSplitOrder(splitorder, i)"
                                                        (touchend)="openSplitOrder(splitorder, i)"><img
                                                            src="../../assets/split-order.svg" />{{ "HOME.SPLIT_LABEL" |
                                                        translate }}</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        (click)="openDeleteOrder(deletorder,i)"
                                                        (touchend)="openDeleteOrder(deletorder,i)"><img
                                                            src="../../assets/delete-order.svg" />{{ "HOME.DELETE_LABEL"
                                                        | translate }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="order-wrapper-footer">
    <button class="btn back-btn" *ngIf="!orderData" (click)="BackNavigate()" (touchend)="BackNavigate()">
        <i class="fa fa-angle-left"></i> {{ "HOME.BACKBUTTON" | translate }}
    </button>
    <button class="btn sync-button" *ngIf="CustomerScreen" (click)="syncERP()" (touchend)="syncERP()">
        <span><img src="../../assets/refresh.svg">{{ "HOME.SYNC_BUTTON" | translate }}</span>
    </button>
    <!--For button Disable styles need to add disabled-btn class-->
    <button class="btn next-button" [ngClass]="{'disabled-btn disabled': !enableNext}" *ngIf="CustomerScreen"
        (click)="navigateTo()"> {{ "HOME.NEXT_BUTTON" | translate }}</button>
    <button class="btn next-button" (click)="submitOrder(success);isClickedOnce = true"
        (touchend)="submitOrder(success);isClickedOnce = true" [class.disabled]="isClickedOnce"
        [ngClass]="{'disabled-btn disabled': !productionOrderForm.valid}" *ngIf="CustomerScreen == false"> {{
        "HOME.SAVE_BUTTON" | translate }}</button>
    <button class="btn back-btn" (click)="openDeleteOrderModal(deletproductorder)"
        (touchend)="openDeleteOrderModal(deletproductorder)" *ngIf="orderData"
        [ngClass]="{'disabled-btn disabled': customizing?.allow_deleteERPOrders == '0'}">
        {{ "HOME.DELETEORDERINTAKE_BUTTON" | translate }}
    </button>
    <button class="btn back-btn" (click)="openModal()" (touchend)="openModal()">
        {{ "HOME.CANCELBUTTON" | translate }}
    </button>
</div>

<!--Modal-->
<ng-template #content_1 let-modal>
    <div class="modal-body Order-line-popup">
        <form [formGroup]="productForm" novalidate>
            <div class="modal-header">
                <h2 class="sub-header p-15">{{ "HOME.ORDER_LINE" | translate }} item: {{itemNumber}}</h2>
            </div>
            <div class="Order-line-block d-flex justify-content-between align-items-center p-15">
                <label>Product</label>
                <div class="input-group">
                    <select class="form-select order-select" formControlName="productId" (change)="selectProduct()">
                        <ng-container *ngFor="let product of productList">
                            <option [value]="product.ProductID">{{product.Productname}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="Order-line-block d-flex justify-content-between align-items-center p-15">
                <label>{{ "HOME.QUANTITY_LABEL" | translate }}</label>
                <div class="input-group">
                    <input type="number" class="form-control order-input" min="1" formControlName="quantity">
                </div>
            </div>
            <div class="Order-line-block d-flex justify-content-between align-items-center p-15">
                <label>{{ "HOME.DESCRIPTION_LABEL" | translate }}</label>
                <div class="input-group">
                    <input type="text" class="form-control order-input" formControlName="description" readonly>
                </div>
            </div>
            <div class="Order-line-block d-flex justify-content-between align-items-center p-15"
                *ngIf="selectedCustomer.batch_on_orderline == '1'">
                <label>Pallets</label>
                <div class="input-group">
                    <input type="number" class="form-control order-input" min="1" formControlName="pallets">
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center popup-actions p-15 border-top">
                <button class="btn back-btn" (click)="modal.close('Close click')">
                    {{ "HOME.CLOSEBUTTON" | translate }}
                </button>
                <button class="btn next-button" (click)="saveProductQuantity()"> {{ "HOME.SAVE_BUTTON" | translate
                    }}</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #success let-modal>
    <div class="sucess-popup">
        <h2 *ngIf="!orderData"><img src="../../assets/green-tick.svg" style="margin-right: 7px;">{{
            "HOME.PRODUCTION_ORDER" | translate }} {{createdOrder.order}} {{ "HOME.CREATED_LABEL" | translate }}</h2>
        <h2 *ngIf="orderData"><img src="../../assets/green-tick.svg" style="margin-right: 7px;">{{
            "HOME.PRODUCTION_ORDER" | translate }} {{createdOrder.order}} {{ "HOME.UPDATED_LABEL" | translate }}</h2>
        <div class="d-flex justify-content-end align-items-center popup-actions">

            <button class="btn next-button" (click)="modal.close('Save click');openModal()">OK</button>
        </div>
    </div>
</ng-template>

<ng-template #orderClosed let-modal>
    <div class="sucess-popup">
        <h2><img src="../../assets/green-tick.svg" style="margin-right: 7px;">{{ "HOME.PRODUCTION_ORDER" | translate }}
            {{orderData?.orderID}} {{ "HOME.CLOSED_LABEL" | translate}}</h2>
        <div class="d-flex justify-content-end align-items-center popup-actions">

            <button class="btn next-button" (click)="modal.close('Save click');openModal()">Ok</button>
        </div>
    </div>
</ng-template>

<!--Checklist Modal-->
<ng-template #checklist let-modal>
    <div class="checklist-popup">
        <div class="modal-header">
            <h4 class="modal-title">Checklist</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="goodsRecived">
                <label class="form-check-label" for="goodsRecived">
                    Goods received without damage
                </label>
            </div>
            <div class="checklist-block">
                <label>Temperature</label>
                <div class="temperature-input">
                    <input type="text" class="form-control">
                    <span>°C</span>
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn print-btn">Print</button>
            <button type="button" class="btn back-btn" (click)="modal.close('Close click')">Cancel</button>
        </div>
    </div>
</ng-template>

<!--Split order model-->
<ng-template #splitorder_1 let-modal>
    <div class="modal-body Order-line-popup">
        <form>
            <div class="modal-header">
                <h2 class="sub-header orderline-header p-15">{{ "HOME.SPLITTOPIC" | translate }}: {{itemNumber}}</h2>
            </div>
            <form [formGroup]="splitForm" novalidate>
                <div class="split-order-quantity d-flex justify-content-between align-items-center p-15">
                    <label>{{ "HOME.CURRENTQUANTITY_LABEL" | translate }}</label>
                    <label class="text-end f-18">{{currentQuantity}} </label>
                </div>
                <div class="split-order-quantity d-flex justify-content-between align-items-center p-15">
                    <label>{{ "HOME.QUAN_SPLITOFF_LABEL" | translate }} </label>
                    <div class="input-group">
                        <input type="number" formControlName="split" class="form-control order-input">
                    </div>
                </div>
            </form>
            <div class="d-flex justify-content-end align-items-center popup-actions p-15 border-top">
                <button class="btn next-button" (click)="splitOrder()"> {{ "HOME.SAVE_BUTTON" | translate }} </button>
                <button class="btn back-btn" (click)="modal.close('Close click')">
                    {{ "HOME.CANCELBUTTON" | translate }}
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #deletorder let-modal>
    <div class="modal-header">
        <h2 class="modal-title title" id="exampleModalLabel">{{ "HOME.DELETETOPIC" | translate }} : {{itemNumber}}</h2>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <p class="confirm-text">{{"HOME.DELETE_QUESTION" | translate}}?</p>
    </div>
    <div class="modal-footer">
        <div class="d-flex justify-content-end align-items-center popup-actions">
            <button class="btn next-button" (click)="deleteInline()"> Ok</button>
            <button class="btn back-btn" (click)="modal.close('Close click')">
                {{ "HOME.CANCELBUTTON" | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #deletproductorder let-modal>
    <div class="modal-header">
        <h2 class="modal-title title" id="exampleModalLabel">{{ "HOME.DELETEORDERINTAKE_BUTTON" | translate }} :
            {{orderData.orderID}}</h2>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <p class="confirm-text">{{"HOME.DELETE_PRODUCT_ORDER" | translate}}?</p>
    </div>
    <div class="modal-footer">
        <div class="d-flex justify-content-end align-items-center popup-actions">
            <button class="btn next-button" (click)="delete(orderClosed)"> Ok</button>
            <button class="btn back-btn" (click)="modal.close('Close click')">
                {{ "HOME.CANCELBUTTON" | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #splitorder let-modal>
    <div class="modal-header">
        <h2 class="modal-title title" id="exampleModalLabel">{{ "HOME.SPLITTOPIC" | translate }}: {{itemNumber}}</h2>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="splitForm" novalidate>
            <div class="split-order-quantity d-flex justify-content-between align-items-center">
                <label>{{ "HOME.CURRENTQUANTITY_LABEL" | translate }}</label>
                <label class="text-end f-18">{{currentQuantity}} </label>
            </div>
            <div class="split-order-quantity d-flex justify-content-between align-items-center">
                <label>{{ "HOME.QUAN_SPLITOFF_LABEL" | translate }} </label>
                <div class="input-group">
                    <input type="number" formControlName="split" class="form-control order-input">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex justify-content-end align-items-center popup-actions">
            <button class="btn next-button" (click)="splitOrder()"> {{ "HOME.SAVE_BUTTON" | translate }} </button>
            <button class="btn back-btn" (click)="modal.close('Close click')">
                {{ "HOME.CANCELBUTTON" | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
        <h2 class="modal-title title" id="exampleModalLabel">{{ "HOME.ORDER_LINE" | translate }} item: {{itemNumber}}
        </h2>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="productForm" novalidate>
            <div class="Order-line-block d-flex justify-content-between align-items-center ">
                <label>Product</label>
                <div class="input-group">
                    <select class="form-select order-select" formControlName="productId" (change)="selectProduct()">
                        <ng-container *ngFor="let product of productList">
                            <option [value]="product.ProductID">{{product.Productname}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="Order-line-block d-flex justify-content-between align-items-center">
                <label>{{ "HOME.QUANTITY_LABEL" | translate }}</label>
                <div class="input-group">
                    <input type="number" class="form-control order-input"
                        [ngClass]="{'quantity': productForm.value.measurement != ''}" min="1"
                        formControlName="quantity">
                    <input type="text" *ngIf="productForm.value.measurement != ''"
                        class="form-control order-input measurement" readonly formControlName="measurement">
                </div>
            </div>
            <div class="Order-line-block d-flex justify-content-between align-items-center ">
                <label>{{ "HOME.DESCRIPTION_LABEL" | translate }}</label>
                <div class="input-group">
                    <input type="text" class="form-control order-input" formControlName="description" readonly>
                </div>
            </div>
            <div class="Order-line-block d-flex justify-content-between align-items-center "
                *ngIf="selectedCustomer.batch_on_orderline == '1'">
                <label>Pallets</label>
                <div class="input-group">
                    <input type="number" class="form-control order-input" min="1" formControlName="pallets">
                </div>
            </div>
            <!-- start -->
            <div formGroupName="customfields">
                <ng-container *ngFor="let customfields of this.customizing?.customfields?.orderlines">
                    <ng-container [ngSwitch]="customfields?.fieldtype">
                        <div *ngSwitchCase="'text'"
                            class="Order-line-block d-flex justify-content-between align-items-center ">
                            <label>{{ customfields.languageKey}}</label>
                            <div class="input-group">
                                <input type="text" class="form-control order-input"
                                    [formControlName]="customfields.languageKey">
                            </div>
                        </div>
                        <div *ngSwitchCase="'date'"
                            class="Order-line-block d-flex justify-content-between align-items-center ">
                            <label>{{ customfields.languageKey}}</label>
                            <div class=" input-group ">
                                <input class="form-control order-input" matInput [matDatepicker]="readyDatedp"
                                    (click)="readyDatedp.open()" (focus)="readyDatedp?.open()"
                                    [formControlName]="customfields.languageKey">
                                <mat-datepicker-toggle class="date" matSuffix [for]="readyDatedp"
                                    id="datepicker"></mat-datepicker-toggle>
                                <mat-datepicker #readyDatedp></mat-datepicker>
                            </div>
                        </div>
                        <div *ngSwitchCase="'textarea'"
                            class="Order-line-block d-flex justify-content-between align-items-center ">
                            <label>{{ customfields.languageKey }}</label>
                            <div class="input-group">
                                <textarea class="form-control order-input" name="" id=""
                                    [formControlName]="customfields.languageKey" cols="1" rows="1"></textarea>

                            </div>
                        </div>
                        <div *ngSwitchCase="'checkbox'"
                            class="Order-line-block d-flex justify-content-between align-items-center ">
                            <label>{{ customfields.languageKey }}</label>
                            <div class="input-group">
                                <input type="checkbox" [formControlName]="customfields.languageKey">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>


        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex justify-content-center align-items-center popup-actions">
            <button class="btn next-button" (click)="saveProductQuantity()"> {{ "HOME.SAVE_BUTTON" | translate
                }}</button>
            <button class="btn back-btn" (click)="modal.close('Close click')">
                {{ "HOME.CLOSEBUTTON" | translate }}
            </button>
        </div>
    </div>
</ng-template>